import axios from "axios";
import PropTypes from "prop-types";
import classNames from "classnames";
import { polygon } from "@turf/helpers";
import buffer from "@turf/buffer";
import bbox from "@turf/bbox";
import { booleanDisjoint, booleanWithin } from "@turf/turf";

import React, { useEffect, useState, useContext } from "react";
import { FeatureGroup, Polygon, Tooltip, withLeaflet } from "react-leaflet";
import { observer as hooksObserver } from "mobx-react-lite";
import { getPublicInvestigationResearchTypes } from "config/config";
import { StoresContext } from "contexts";
import { useDebouncedCallback } from "use-debounce";
import { PUBLIC_INVESTIGATION_TYPES, PUBLIC_INVESTIGATION_STATUS, PUBLIC_INVESTIGATION_RESEARCH_TYPE } from "stores/models/PublicInvestigationModel";
import { TimeSlicer } from "@orbit/components";
import { fetchDepartments, getParcelsByBounds } from "services/api";
import { toJS } from "mobx";
import { Typography } from "@mui/material";

const isZoomInRange = (currentZoom, minZoom, maxZoom) => currentZoom >= minZoom && currentZoom <= maxZoom;

let cancelToken = axios.CancelToken;
let cancelSource;
/**
 * layer responsible for showing parcels polygons
 * the layer can also be clicked to change the
 * selected parcels the selected parcels are controlled via props
 * so if we want to change the selection via clicks
 * we should also pass a function to setSelectedParcels
 *
 */
const ParcelPolygonViewer = hooksObserver(({ leaflet, selectedParcels, onClick, maxZoom = 20, minZoom = 14, minLoadZoom = 16, canEdit = false }) => {
  const {
    applicationStore: { publicInvestigation, publicInvestigation: { researchType, leggerType, secondBufferDistance } },
    authStore: { nis },
  } = useContext(StoresContext);

  const [polygons, setPolygons] = useState({});
  // const [precalculatedBounds, setPrecalculatedBounds] = useState([]);
  const [currentZoom, setCurrentZoom] = useState(leaflet.map.getZoom());
  const isEnabled = isZoomInRange(currentZoom, minZoom, maxZoom);
  const [enabled, setEnabled] = useState(isEnabled);
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    const getDep = async () => {
      try {
        const dep = await fetchDepartments(nis, leggerType === "fiscaal" ? "fiscal" : "actual");
        setDepartments(dep.departments.map((dep) => dep.departmentCode));
      } catch (e) {
        console.error("ERROR:", e.toString());
      }
    };
    getDep();
  }, [nis]);

  /**
   * added debounce to fetching of polygons
   * will increase performance feel
   * and reduce calls made to the api when scrolling/zooming map
   */
  const [getPolygonsDebounced] = useDebouncedCallback(async (bbox) => {
    if (cancelSource) {
      cancelSource.cancel();
    }

    cancelSource = cancelToken.source();
    let data = [];
    for (const box of bbox) {
      try {
        const result = await getParcelsByBounds(box, publicInvestigation.leggerType, cancelSource);
        if (result) {
          data = [...data, ...result];
        }
      } catch (error) {}
    }
    if (data) {
      setPolygons({ ...polygons, ...data.reduce((acc, feature) => ({ ...acc, [feature.capakey]: feature }), {}) });
    }
  }, 500);

  /**
   * effect that will calculate the bounds to use when we zoom out
   * so we don't have to load the full bounds of the map
   * but only the maximum required bbox for the public investigation
   * will seet precalculatedBounds that will ask for all polygons needed
   */
  useEffect(() => {
    if (publicInvestigation.publicInvestigationBuffers[secondBufferDistance] && publicInvestigation.publicInvestigationBuffers[secondBufferDistance].coordinates.length > 0) {
      let bounds = [];
      if (publicInvestigation.publicInvestigationBuffers[secondBufferDistance].type === "Polygon") {
        const bufferLocation = buffer(polygon(publicInvestigation.publicInvestigationBuffers[secondBufferDistance].coordinates), 700, {
          units: "meters",
        });

        var boundingbox = bbox(bufferLocation);

        const bound = {
          _southWest: { lat: boundingbox[1], lng: boundingbox[0] },
          _northEast: { lat: boundingbox[3], lng: boundingbox[2] },
        };
        bounds.push(bound);
      } else {
        bounds = publicInvestigation.publicInvestigationBuffers[secondBufferDistance].coordinates.map((coordinates) => {
          const bufferLocation = buffer(polygon(coordinates), 10, {
            units: "meters",
          });

          var boundingbox = bbox(bufferLocation);

          const bound = {
            _southWest: { lat: boundingbox[1], lng: boundingbox[0] },
            _northEast: { lat: boundingbox[3], lng: boundingbox[2] },
          };
          return bound;
        });
      }
      getPolygonsDebounced(bounds);
    }
  }, [publicInvestigation.publicInvestigationBuffers]);

  /**
   * when the component is enabled we want to load new polygons
   * every time the map moves
   */
  useEffect(() => {
    const getParcelPolygons = async () => {
      if (
        publicInvestigation.status === PUBLIC_INVESTIGATION_STATUS.SHOW_ON_MAP ||
        (publicInvestigation.type === PUBLIC_INVESTIGATION_TYPES.SELECT_SHAPES && publicInvestigation.status === PUBLIC_INVESTIGATION_STATUS.SELECT_ON_MAP)
      ) {
        let bounds = [leaflet.map.getBounds()];
        try {
          if (bounds.length > 0) {
            if (!enabled || (enabled && currentZoom < minLoadZoom)) {
              return false;
            }
            getPolygonsDebounced(bounds);
          }
        } catch (error) {
          console.error("ERROR:", error.toString());
        }
      }
    };

    getParcelPolygons();

    leaflet.map.on("moveend", getParcelPolygons);
    return () => leaflet.map.off("moveend", getParcelPolygons);
  }, [enabled, currentZoom, minLoadZoom, publicInvestigation.type, publicInvestigation.status]);

  /**
   * effect listening to the leaflet map zoom
   * when the zoom is out of range we disable the component
   * we don't want to load to many polygons when that happens
   */
  useEffect(() => {
    const checkZoom = () => {
      const zoom = leaflet.map.getZoom();
      setCurrentZoom(zoom);
      const isEnabled = isZoomInRange(zoom, minZoom, maxZoom);
      setEnabled(isEnabled);
    };
    leaflet.map.on("zoomend", checkZoom);

    return () => leaflet.map.off("zoomend", checkZoom);
  }, []);

  useEffect(() => {
    const geoJsonData = {
      type: "FeatureCollection",
      features: [],
    };
    const geoJsons = Object.entries(selectedParcels).reduce((acc, [key, value]) => {
      const feature = polygons[key];
      if (feature) {
        return [
          ...acc,
          {
            type: "Feature",
            properties: {
              type: value.type,
              capakey: feature.capakey,
              isOutsideBoundary: departments.length > 0 ? !departments.includes(feature.capakey.substr(0, 5)) : false,
            },
            geometry: {
              type: "Polygon",
              coordinates: feature.coordinates,
            },
          },
        ];
      }
      return acc;
    }, []);

    geoJsonData.features = geoJsons;
    publicInvestigation.setGeoJsonData(geoJsonData);
    console.log("geoJsonData", geoJsonData);
  }, [polygons, selectedParcels, departments]);

  return (
    <TimeSlicer>
      <FeatureGroup>
        {Object.values(polygons).length > 0 &&
          Object.values(polygons).map((geoJson) => {
            const activeParcel = selectedParcels[geoJson.capakey];
            const isSelected = activeParcel ? true : false;
            const isOutsideBoundary = publicInvestigation.geoJsonData.features.find((feature) => feature.properties.capakey === geoJson.capakey)?.properties.isOutsideBoundary;

            let type = "";
            //let bufferGeojson = null;

            if (activeParcel) {
              const currentType = getPublicInvestigationResearchTypes(secondBufferDistance).find((researchtype) => researchtype.value === activeParcel.type);
              /*if(currentType.value === "C") {
                  bufferGeojson = geoJson;
                  console.log(bufferGeojson)
                }*/
              type = currentType && currentType.label;
            }

            if (isOutsideBoundary) {
              type = <Typography variant="body2">Niet binnen gemeentegrens (N)</Typography>;
            }

            /**
             * responsible for styling active parcels
             * will append the type to the classnames
             * add type to mapstyles
             *
             */
            const dynamicClasses = {};
            if (activeParcel) {
              if (isOutsideBoundary) {
                dynamicClasses["N"] = true;
              } else {
                if (researchType === PUBLIC_INVESTIGATION_RESEARCH_TYPE.PERIMETER && publicInvestigation.status > 2) {
                  dynamicClasses["P"] = true;
                } else {
                  dynamicClasses[activeParcel.type] = true;
                }
              }
            }
            const polygonClasses = classNames("parcel", dynamicClasses);

            /**
             * when the map is enabled and the item is not in selection
             * we should add the parcels -> so not selected parcels
             * will be hidden when zoom is to out of range
             * will help for performance + bettter rendering
             */
            if (!isEnabled && !isSelected) {
              return false;
            }

            return (
              /*<React.Fragment>
                  {bufferGeojson !== null &&
                    <>
                      <Polygon key="small-buffer" positions={flip(buffer({...flip(bufferGeojson), capakey: geoJson.capakey}, 114, { units: "meters" })).geometry.coordinates}></Polygon>
                      <Polygon key="large-buffer" positions={flip(buffer({...flip(bufferGeojson), capakey: geoJson.capakey}, 341, { units: "meters" })).geometry.coordinates}></Polygon>
                    </>
                  }*/
              <React.Fragment key={`${geoJson.capakey}-${isSelected}-${activeParcel && activeParcel.type}-container`}>
                {(isSelected || (!isSelected && currentZoom > minLoadZoom)) && (
                  <Polygon
                    key={`${geoJson.capakey}-${isSelected}-${activeParcel && activeParcel.type}-${researchType}`}
                    className={polygonClasses}
                    onclick={
                      canEdit && onClick
                        ? () => {
                            onClick(geoJson);
                          }
                        : null
                    }
                    positions={geoJson.coordinates}
                  >
                    <Tooltip>
                      {geoJson.capakey}
                      {researchType === PUBLIC_INVESTIGATION_RESEARCH_TYPE.PERIMETER ? "" : <> {type}</>}
                    </Tooltip>
                  </Polygon>
                )}
              </React.Fragment>
              /*</React.Fragment>*/
            );
          })}
      </FeatureGroup>
    </TimeSlicer>
  );
});

ParcelPolygonViewer.propTypes = {
  selectedParcels: PropTypes.object,
};

export default withLeaflet(ParcelPolygonViewer);
