import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import axios from "axios";
import { MESSAGE_TYPES } from "config/config";
import { getMagdaNotificationTitle, getMagdaNotificationType } from "config/magdaMessages";
import { create } from "mobx-persist";
import { Provider } from "mobx-react";
import { RouterStore } from "mobx-react-router";
import React from "react";
import ReactDOM from "react-dom";
import { setDefaultHeader } from "services/api";
import App from "./App";
import { StoresContext } from "./contexts";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import ApplicationStore from "./stores/ApplicationStore";
import AuthStore from "./stores/AuthStore";
import MapStore from "./stores/MapStore";
import UiStore from "./stores/UiStore";
import NotifierStore from "./views/notifier/NotifierStore";

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: "0d81b30a-6339-45c3-b6de-7c1bdd13c5e9",
  },
});
appInsights.loadAppInsights();
appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview

// setup all stores
const routingStore = new RouterStore();
const uiStore = new UiStore();
const mapStore = new MapStore();
const authStore = new AuthStore(routingStore, mapStore);
const applicationStore = new ApplicationStore(authStore, uiStore);
const notifierStore = NotifierStore;

export const stores = {
  routingStore,
  uiStore,
  authStore,
  mapStore,
  applicationStore,
  notifierStore,
};

const persistStores = async () => {
  const hydrate = create();

  await hydrate("uiStore", stores.uiStore);
  await hydrate("authStore", stores.authStore);
  await hydrate("mapStore", stores.mapStore);
  // await hydrate("applicationStore", stores.applicationStore);
};

// Add a 401 response interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const rawMessage = JSON.stringify(error.response.data.errors);

    if (error.response.status === 400) {
      return NotifierStore.enqueueSnackbar({
        message: {
          message: [error.response.data.message],
          title: "Uw aanvraag voldoet niet aan de vereisten",
          isExpanded: true,
          variant: MESSAGE_TYPES.WARNING,
          link: {
            text: "Meer informatie om aan te sluiten",
            url: "https://www.geoportaal.be/welke-geoportaal-formule-past-in-jouw-organisatie/",
          },
        },
        options: {
          key: new Date().getTime() + Math.random(),
          variant: MESSAGE_TYPES.WARNING,
          persist: true,
        },
      });
    }

    if (error.response.status === 401) {
      return authStore.doLogout(true);
    }

    if (error.response.status === 402) {
      return NotifierStore.enqueueSnackbar({
        message: {
          message: [error.response.data.message],
          title: "Aanmelden niet gelukt",
          isExpanded: true,
          variant: MESSAGE_TYPES.WARNING,
          link: {
            text: "Meer informatie om aan te sluiten",
            url: "https://www.geoportaal.be/welke-geoportaal-formule-past-in-jouw-organisatie/",
          },
        },
        options: {
          key: new Date().getTime() + Math.random(),
          variant: MESSAGE_TYPES.WARNING,
          persist: true,
        },
      });
    }

    if (error.response.status === 403) {
      return NotifierStore.enqueueSnackbar({
        message: error.response.data.message,
      });
    }

    if (error.response.status === 404) {
      NotifierStore.enqueueSnackbar({ message: "Geen resultaten gevonden" });
    }

    if (error.response.status === 426) {
      return NotifierStore.enqueueSnackbar({
        message: "Deze optie zit niet in uw formule!",
        options: { variant: MESSAGE_TYPES.INFO },
      });
    }

    if (error.response.status === 500 && error.config.url.includes("actueel")) {
      let messages = [];
      let variant = MESSAGE_TYPES.UNKNOWN;
      try {
        messages = error.response.data.errors.reduce((previous, error) => {
          if (error.request.Uitzondering) {
            const exceptions = error.request.Uitzondering.map?.((uitzondering) => uitzondering.Diagnose) || [];
            variant = getMagdaNotificationType(exceptions);
            previous = [...previous, ...exceptions];
            return previous;
          }

          if (Array.isArray(error.request)) {
            variant = getMagdaNotificationType(error.request);
            const errors = error.request.map((requestError) => {
              return requestError.Diagnose;
            });

            previous = [...previous, ...errors];
            return previous;
          }

          previous = [...previous, rawMessage];
          return previous;
        }, []);
      } catch (error) {
        messages = [rawMessage];
      }

      return NotifierStore.enqueueSnackbar({
        message: { title: getMagdaNotificationTitle(variant), message: messages, isExpanded: true, variant },
        options: {
          key: new Date().getTime() + Math.random(),
          variant,
          persist: true,
        },
      });
    }
  },
);

const renderApp = async () => {
  ReactDOM.render(<div>loading application...</div>, document.getElementById("root"));
  await persistStores();

  const { token } = stores.authStore;
  if (token) setDefaultHeader(token);

  ReactDOM.render(
    <Provider {...stores}>
      {/* inject of stores for react hooks combined with mobx */}
      <StoresContext.Provider value={stores}>
        <App />
      </StoresContext.Provider>
    </Provider>,
    document.getElementById("root"),
  );
};

renderApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
