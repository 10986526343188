import flip from "@turf/flip";
import { action, computed, observable, toJS, autorun } from "mobx";
import moment from "moment";
import "moment/locale/nl-be";
import { getPublicInvestigationHistory, savePublicInvestigationHistory, fetchPublicInvestigationResults } from "services/api";

const customMoment = moment();
customMoment.locale("nl-be");

export default class PublicInvestigationModel {
  @observable
  _location = {
    type: "FeatureCollection",
    features: [],
  };

  @observable
  _shape = null;

  @observable
  _geoJsonData = null;

  @observable
  leggerType = null;

  @observable
  houseHolder = false;

  @observable
  deduplicate = false;

  @observable
  drawedGeoJson = {
    type: "FeatureCollection",
    features: [],
  };

  @observable
  email = "";

  @observable
  titel = customMoment.format("lll");

  @observable
  type = PUBLIC_INVESTIGATION_TYPES.NONE;

  @observable
  status = PUBLIC_INVESTIGATION_STATUS.START;

  @observable
  searchType = PUBLIC_INVESTIGATION_SEARCH_TYPE.OWNERS;

  @observable
  researchType = PUBLIC_INVESTIGATION_RESEARCH_TYPE.LOCATION_INDICATION;

  @observable
  locationIndicationOptions = { A: true, I: false, B: false, C: false };

  @observable
  perimeter = 50;

  @observable
  secondBufferDistance = 300;

  @observable
  lastResult = null;

  /**
   * these are the parcels that are added by the user
   * from this selection the public investigation is started
   * these are defined is in step 2
   * and is a object of capakey and coordinates and type c
   * type c stands for current selection
   * the capakey is the key and value
   * {"44803C0456/00B000": { type: "C", CAPAKEY: "44803C0456/00B000", coordinates }}
   * we use this as a dictionary so we don't have to loop true the
   * values to get a value -> performance reasons
   */
  @observable
  selectedParcels = {};

  /**
   * is used to show all selected parcels after the selection is made
   * step 4/5 in public investigation
   * parcels with following format
   * {"44803C0456/00B000": { type: "B", CAPAKEY: "44803C0456/00B000" }}
   * capakey -> and type we have four types A/I/B/C
   */
  @observable
  _publicInvestigationParcels = {};

  @observable
  _publicInvestigationBuffer = {};

  @observable
  _publicInvestigationPerimeterBuffer = null;

  @action
  reset = () => {
    this._location = {
      type: "FeatureCollection",
      features: [],
    };

    this._shape = null;

    this.perimeter = 50;
    this.secondBufferDistance = 300;
    this.selectedParcels = {};
    this.drawedGeoJson = {
      type: "FeatureCollection",
      features: [],
    };
    this._publicInvestigationParcels = {};
    this._publicInvestigationBuffer = {};
    this._publicInvestigationBuffer = {};
    this.status = PUBLIC_INVESTIGATION_STATUS.START;
    this.searchType = PUBLIC_INVESTIGATION_SEARCH_TYPE.OWNERS;
    this.researchType = PUBLIC_INVESTIGATION_RESEARCH_TYPE.LOCATION_INDICATION;
    this.locationIndicationOptions = { A: true, I: false, B: false, C: false, P: false };
  };

  @action
  setType = async (type) => {
    /**
     * when type is changed we can reset the model
     * because when changing type all setted properties
     * can cause some conflicts
     */
    this.reset();
    this.type = type;
  };

  @action
  setLeggertype = (type) => {
    this.leggerType = type;
  };

  @action
  setHouseHolder = (value) => {
    this.houseHolder = value;
  };

  @action
  setDeduplicate = (value) => {
    this.deduplicate = value;
  };

  @action
  setTitle = (titel) => {
    this.titel = titel;
  };

  @action
  setStatus = async (status) => {
    this.status = status;
  };

  @action
  setEmail = (email) => {
    this.email = email;
  };

  @action
  setDrawedGeoJson = async (geoJson) => {
    console.log("set drawed", geoJson);
    this.drawedGeoJson = geoJson;
  };

  @action
  setSelectedParcels = async (parcels) => {
    this.selectedParcels = parcels;
  };

  @action
  updateSelection = ({ capakey, coordinates }) => {
    const clone = { ...this.selectedParcels };
    if (!clone[capakey]) {
      clone[capakey] = { type: "C", CAPAKEY: capakey, coordinates };
      this.setSelectedParcels(clone);
    } else {
      delete clone[capakey];
      this.setSelectedParcels(clone);
    }
  };

  @action
  setPublicInvestigationParcels = async (parcels) => {
    this._publicInvestigationParcels = parcels;
  };

  @action
  setPublicInvestigationPerimeterBuffer = async (buffer) => {
    this._publicInvestigationPerimeterBuffer = buffer;
  };

  @action
  setPublicInvestigationBuffers = async (buffer100, buffer300) => {
    this._publicInvestigationBuffer = {
      100: JSON.parse(buffer100),
      [this.secondBufferDistance]: JSON.parse(buffer300),
    };
  };

  /**
   * this function will update the pi parcels
   * when an item is clicked depending on the researchtype
   * it will loop through the possible types
   */
  @action
  updatePublicInvestigationParcels = ({ capakey, coordinates }) => {
    const locationTypes = [];
    Object.entries(this.locationIndicationOptions).forEach(([key, value]) => {
      if (value) {
        locationTypes.push(key);
      }
    });
    const possibleTypes = {
      perimeter: ["I"],
    };
    possibleTypes[this.researchType] = locationTypes;

    const clone = { ...this._publicInvestigationParcels };
    const parcel = clone[capakey];
    const currentTypes = possibleTypes[this.researchType];
    if (!parcel) {
      clone[capakey] = {
        type: currentTypes[0],
        CAPAKEY: capakey,
        coordinates,
      };
      this.setPublicInvestigationParcels(clone);
    } else {
      const index = currentTypes.indexOf(parcel.type);
      if (index === currentTypes.length) {
        delete clone[capakey];
      } else {
        parcel.type = currentTypes[index + 1];
      }
      this.setPublicInvestigationParcels(clone);
    }
  };

  @action
  setSearchType = async (searchType) => {
    this.searchType = searchType;
  };

  @action
  setResearchType = async (researchType) => {
    this._publicInvestigationParcels = {};
    this.researchType = researchType;
  };

  @action
  setPerimeter = async (perimeter) => {
    this.perimeter = perimeter;
  };

  @action
  setSecondBufferDistance = async (secondBufferDistance) => {
    this.secondBufferDistance = secondBufferDistance;
  };

  @action
  setLocationIndicationOptions = (value) => {
    this.locationIndicationOptions = value;
  };

  @action
  setShape = async (value) => {
    this._shape = value;
  };

  @action
  setGeoJsonData = async (value) => {
    this._geoJsonData = value;
  };

  @action
  load = async (id) => {
    const { data = [] } = await getPublicInvestigationHistory(id);
    if (data[0]) {
      Object.assign(this, data[0]);
    }
    this.secondBufferDistance = this.secondBufferDistance || 300;
  };

  @action
  save = async (id = null) => {
    const { data } = await savePublicInvestigationHistory(toJS(this), id);
    if (data) {
      return data.id;
    }
    return null;
  };

  @action
  fetchResults = async (id) => {
    const data = await fetchPublicInvestigationResults(id);
    if (data) {
      this.setLastResult(data);
    }
    return data;
  };

  @action
  setLastResult = (val) => {
    this.lastResult = val;
  };

  /**
   * method used for validating public investigation steps
   * depending on the pi type and status
   */
  @action
  getStepValidator = () => {
    const location = this.location;

    if (this.type === PUBLIC_INVESTIGATION_TYPES.SEARCH || this.type === PUBLIC_INVESTIGATION_TYPES.IMPORT_SHAPE || this.type === PUBLIC_INVESTIGATION_TYPES.DRAW) {
      return Object.keys(location).length < 1;
    }

    return location.features.length < 1;
  };

  /**
   * wrap _shape into feature collection -> shape is returned from
   * api but to be a valid geosjon we have to wrap it into a feature collection
   */
  @computed
  get shape() {
    if (this._shape && this.type === PUBLIC_INVESTIGATION_TYPES.IMPORT_SHAPE) {
      return this._shape;
    } else if (this._shape && this.type === PUBLIC_INVESTIGATION_TYPES.SEARCH) {
      console.log("shape " + PUBLIC_INVESTIGATION_TYPES.SEARCH, this._shape);
      return {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: this._shape,
          },
        ],
      };
    }

    return {};
  }

  /**
   * method used for getting the public investigation parcels
   * depending on the pi type we give the correct geosjon
   */
  @computed
  get location() {
    if (this.type === PUBLIC_INVESTIGATION_TYPES.SELECT_SHAPES) {
      const location = toJS(this._location);
      Object.entries(this.selectedParcels).forEach(([key, parcel]) => {
        location.features.push({
          type: "Feature",
          properties: {},
          geometry: {
            type: "Polygon",
            coordinates: parcel.coordinates,
          },
        });
      });

      return flip(location);
    } else if (this.type === PUBLIC_INVESTIGATION_TYPES.DRAW) {
      return this.drawedGeoJson;
    } else if (this.type === PUBLIC_INVESTIGATION_TYPES.SEARCH || this.type === PUBLIC_INVESTIGATION_TYPES.IMPORT_SHAPE) {
      const shape = toJS(this.shape);
      return shape;
    } else if (this.type === PUBLIC_INVESTIGATION_TYPES.IMPORT_DATASET) {
      const location = toJS(this._location);
      Object.entries(this.selectedParcels).forEach(([key, parcel]) => {
        if (parcel.coordinates) {
          location.features.push({
            type: "Feature",
            properties: {},
            geometry: {
              type: "Polygon",
              coordinates: parcel.coordinates,
            },
          });
        }
      });

      return location;
    }

    return {
      type: "FeatureCollection",
      features: [],
    };
  }

  /**
   * will only show the public investigation parcels
   * that are in the locationIndicationOptions true
   *
   */
  @computed
  get publicInvestigationParcels() {
    if (this.researchType === PUBLIC_INVESTIGATION_RESEARCH_TYPE.LOCATION_INDICATION || this.researchType === PUBLIC_INVESTIGATION_RESEARCH_TYPE.WITHIN_LOCATION) {
      const parcels = {};
      // loop all parcels return value
      Object.entries(this._publicInvestigationParcels).forEach(([key, value]) => {
        if (this.locationIndicationOptions[value.type]) {
          parcels[key] = value;
        }
      });
      return parcels;
    }

    if (this.researchType === PUBLIC_INVESTIGATION_RESEARCH_TYPE.WITHIN_LOCATION) {
      const parcels = {};
      // loop all parcels return value
      Object.entries(this._publicInvestigationParcels).forEach(([key, value]) => {
        if (this.locationIndicationOptions["C"]) {
          parcels[key] = value;
        }
      });
      return parcels;
    }
    return this._publicInvestigationParcels;
  }

  @computed
  get publicInvestigationBuffers() {
    return this._publicInvestigationBuffer;
  }

  @computed
  get publicInvestigationPerimeterBuffer() {
    return this._publicInvestigationPerimeterBuffer;
  }

  @computed
  get geoJsonData() {
    return this._geoJsonData;
  }
}

export const PUBLIC_INVESTIGATION_STATUS = Object.freeze({
  START: 0,
  SELECT_ON_MAP: 1,
  TYPE_SEARCH: 2,
  TYPE_INVESTIGATION: 3,
  SHOW_ON_MAP: 4,
  SHOW_RESULT: 5,
});

export const PUBLIC_INVESTIGATION_TYPES = Object.freeze({
  NONE: 0,
  DRAW: 1,
  SELECT_SHAPES: 2,
  SEARCH: 3,
  IMPORT_SHAPE: 4,
  IMPORT_DATASET: 5,
});

export const PUBLIC_INVESTIGATION_SEARCH_TYPE = Object.freeze({
  OWNERS: "owners",
  RESIDENTS: "residents",
});

export const PUBLIC_INVESTIGATION_RESEARCH_TYPE = Object.freeze({
  LOCATION_INDICATION: "location",
  PERIMETER: "perimeter",
  WITHIN_LOCATION: "within_location",
});
