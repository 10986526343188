import React, { useContext } from "react";
import { withStyles } from "@mui/styles";
import { withLeaflet } from "react-leaflet";
import { withRouter } from "react-router";
import { observer as hooksObserver } from "mobx-react-lite";

import styles from "./MapStyles";
import BaseMap from "./shared-map-components";
import { LayerSelector } from "@orbit/components";
import { StoresContext } from "contexts";

const MapBaseLayer = hooksObserver(({ onCloseSelector, mapSelectorOpen }) => {
  const {
    mapStore: { mapLayer: { activeMap, maps, activeMapIndex, setActiveMapIndex, activeOverlay, overlays, activeOverlayIndex, setActiveOverlayIndex } },
    authStore: { token: bearer },
  } = useContext(StoresContext);

  return (
    <>
      <BaseMap map={activeMap} overlay={activeOverlay} bearer={bearer} />
      <LayerSelector
        isOpen={mapSelectorOpen}
        title={"Kies een Kaartlaag"}
        onClose={(e) => onCloseSelector(false)}
        overlayLayers={{
          title: "Overlay",
          layers: overlays?.length ? [{ label: "Geen", value: -1 }, ...overlays.map((l, i) => ({ label: l.title, value: i }))] : [],
          onChange: (index) => {
            setActiveOverlayIndex(Number(index));
            onCloseSelector(false);
          },
          value: activeOverlayIndex,
          info: "Een overlay kan enkel getoond worden bij een ingezoomde kaart.",
        }}
        baseLayers={{
          value: activeMapIndex,
          layers: maps,
          onChange: (index) => {
            setActiveMapIndex(index);
            onCloseSelector(false);
          },
        }}
      />
    </>
  );
});

export default withLeaflet(withRouter(withStyles(styles)(MapBaseLayer)));
