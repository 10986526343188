import { MAP_TYPES } from "app-constants";
import React from "react";
import { TileLayer, WMSTileLayer } from "react-leaflet";
import WMTSTileLayer from "react-leaflet-wmts";
import VectorTileLayer from "./VectorTileLayer";

const { API_URL } = window.env;

const MapLayer = ({ bearer, ...props }) => {
  return (
    <>
      {props.type === MAP_TYPES.WMTS && <WMTSTileLayer {...props} />}
      {props.type === MAP_TYPES.TILE && <TileLayer {...props} />}
      {props.type === MAP_TYPES.WMS && <WMSTileLayer {...props} />}
      {props.type === MAP_TYPES.WFS && (
        <VectorTileLayer
          {...props.options}
          style={{ interactive: false, fill: true, stroke: true, fillRule: "nonzero", ...props.style }}
          url={`${API_URL}wfs/get-pbf/${props.transferLayerId}/{z}/{x}/{y}`}
          {...(bearer ? { fetchOptions: { headers: { authorization: `Bearer ${bearer}` } } } : {})}
        />
      )}
    </>
  );
};

const BaseMap = ({ map, overlay, bearer }) => {
  return (
    <>
      {!!map && <MapLayer key={map.title} {...map} zIndex={1} bearer={bearer} />}
      {!!overlay && <MapLayer key={overlay.title} {...overlay} zIndex={2} bearer={bearer} />}
    </>
  );
};

export default BaseMap;
