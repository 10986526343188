import { withStyles } from "@mui/styles";
import LayersIcon from "@mui/icons-material/Layers";
import { ControlButton, LayerSelector, Map, CustomLinearProgress } from "@orbit/components";
import { StoresContext } from "contexts";
import Logo from "layout/img/MapLogo.png";
import L from "leaflet";
import { observer as hooksObserver } from "mobx-react-lite";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FeatureGroup, GeoJSON, Pane, withLeaflet, Polygon } from "react-leaflet";
import { PUBLIC_INVESTIGATION_STATUS, PUBLIC_INVESTIGATION_TYPES } from "stores/models/PublicInvestigationModel";
import styles from "./MapStyles";
import ParcelPolygonViewer from "./ParcelPolygonViewer";
import ProgressStepper from "./ProgressStepper";
import BaseMap from "./shared-map-components";
import { toJS } from "mobx";
import RenderIf from "components/RenderIf";
import EditControlExample from "./TestTools";
import MapBaseLayer from "./MapBaseLayer";
import { flip, bbox, point, featureCollection, polygon } from "@turf/turf";

const logo = {
  img: Logo,
  url: "https://www.orbitgis.com/",
};

function needsFlipping(coordinates) {
  return coordinates[0][0][0] > coordinates[0][0][1];
}

function calculateBoundsForSelectedParcels(data) {
  try {
    const polygons = [];

    for (const feature of Object.values(mydata)) {
      let mypoly = polygon(feature.coordinates);
      if (needsFlipping(mypoly.geometry.coordinates)) {
        mypoly = flip(mypoly).geometry.coordinates;
      }
      polygons.push(L.polygon(mypoly));
    }

    return L.featureGroup(polygons).getBounds();
  } catch (error) {
    console.log("ERROR(calculateBoundsForSelectedParcels): ", error.toString());
    return null;
  }
}

const MapPublicInvestigationView = hooksObserver(({ classes }) => {
  const {
    mapStore,
    mapStore: { setZoomLevel, zoomLevel, latLng, bounds, setBounds, mapLayer: { activeMap }, setBoundsForGeoJson },
    applicationStore: { publicInvestigation, publicInvestigation: { secondBufferDistance }, loading },
    authStore: { boundary },
    notifierStore,
  } = useContext(StoresContext);

  const [geoJsonData, setGeoJsonData] = useState(null);
  const [currentStatus, setCurrentStatus] = useState(publicInvestigation?.status);

  let mapRef = useRef();
  let bufferRef = useRef();
  let drawShapeRef = useRef();

  const [enableLayerSelector, setEnableLayerSelector] = useState(false);
  const [publicInvestigationId, setPublicInvestigationId] = React.useState();

  const onZoom = (action) => {
    if (action === "zoomIn") setZoomLevel(zoomLevel + 1);
    else if (action === "zoomOut") setZoomLevel(zoomLevel - 1);
  };

  useEffect(() => {
    setInterval(() => {
      if (bufferRef.current) {
        bufferRef.current.leafletElement.bringToFront();
      }
    }, 1000);
  }, []);

  /**
   * will center map to application on load
   */
  useEffect(() => {
    if (mapRef.current && publicInvestigation.location && publicInvestigation.id !== publicInvestigationId) {
      mapStore.center(publicInvestigation.location);
      setPublicInvestigationId(publicInvestigation.id);
    }
  }, [publicInvestigation.location, publicInvestigationId]);

  const publicInvestigationParcelsObject = {
    onClick: publicInvestigation.updatePublicInvestigationParcels,
    selectedParcels: publicInvestigation.publicInvestigationParcels,
    canEdit: publicInvestigation.status === PUBLIC_INVESTIGATION_STATUS.SHOW_ON_MAP,
  };

  const selectedParcelsObject = {
    onClick: publicInvestigation.updateSelection,
    selectedParcels: publicInvestigation.selectedParcels,
    canEdit:
      publicInvestigation.status === PUBLIC_INVESTIGATION_STATUS.SELECT_ON_MAP &&
      publicInvestigation.status === 1 &&
      publicInvestigation.type !== PUBLIC_INVESTIGATION_TYPES.IMPORT_DATASET,
  };

  let polygonObject = {};

  if (publicInvestigation.status < PUBLIC_INVESTIGATION_STATUS.TYPE_INVESTIGATION) {
    polygonObject = selectedParcelsObject;
  } else if (publicInvestigation.status >= PUBLIC_INVESTIGATION_STATUS.TYPE_INVESTIGATION) {
    polygonObject = publicInvestigationParcelsObject;
  }

  useEffect(() => {
    if (publicInvestigation?.geoJsonData && !!Object.keys(publicInvestigation.geoJsonData).length && publicInvestigation.geoJsonData.features?.length) {
      setGeoJsonData(toJS(publicInvestigation?.geoJsonData));
    } else {
      setGeoJsonData(null);
    }
  }, [publicInvestigation?.geoJsonData]);

  useEffect(() => {
    if (mapRef && geoJsonData && publicInvestigation?.status !== currentStatus) {
      setBoundsForGeoJson(geoJsonData);
      setCurrentStatus(publicInvestigation?.status);
    }
  }, [publicInvestigation?.status, mapRef, geoJsonData]);

  useEffect(() => {
    if (publicInvestigation?.status === PUBLIC_INVESTIGATION_STATUS.TYPE_SEARCH && Object.keys(publicInvestigation?.selectedParcels).length && mapRef?.current) {
      const bounds = calculateBoundsForSelectedParcels(publicInvestigation.selectedParcels);
      if (!bounds) return;
      mapRef.current.leafletElement.fitBounds(bounds);
    }
  }, [publicInvestigation?.status, publicInvestigation?.selectedParcels, mapRef]);

  const zoom = {
    currentLevel: zoomLevel,
    zoom: onZoom,
    maxLevel: activeMap ? activeMap.maxZoom : 20,
    minLevel: activeMap ? activeMap.minZoom : 1,
  };

  const onZoomend = (action) => {
    setZoomLevel(action.target._zoom);
  };

  return (
    <React.Fragment>
      <Map
        zoom={zoom}
        latLng={latLng}
        logo={logo}
        header={true}
        mapRef={mapRef}
        bounds={bounds}
        onViewportChanged={() => setBounds(null)}
        style={{
          height: "calc(100% - 128px)",
        }}
        customClass={classes.publicInvestigationMap}
        onZoomend={onZoomend}
      >
        <RenderIf
          condition={
            publicInvestigation.status > PUBLIC_INVESTIGATION_STATUS.TYPE_SEARCH &&
            publicInvestigation.publicInvestigationBuffers[100] &&
            publicInvestigation.publicInvestigationBuffers[secondBufferDistance] &&
            !publicInvestigation.publicInvestigationPerimeterBuffer
          }
        >
          <FeatureGroup ref={bufferRef}>
            <GeoJSON key={Math.random()} data={toJS(publicInvestigation.publicInvestigationBuffers[100])} className={classes.buffer100} />
            <GeoJSON key={Math.random()} data={toJS(publicInvestigation.publicInvestigationBuffers[secondBufferDistance])} className={classes.buffer300} />
          </FeatureGroup>
        </RenderIf>

        <RenderIf condition={boundary?.geometry?.coordinates.length !== 0}>
          <FeatureGroup>
            <Polygon className={classes.municipalityBoundary} positions={flip(boundary).geometry.coordinates} />
          </FeatureGroup>
        </RenderIf>

        <RenderIf condition={publicInvestigation.status > PUBLIC_INVESTIGATION_STATUS.TYPE_SEARCH && publicInvestigation.publicInvestigationPerimeterBuffer}>
          <FeatureGroup ref={bufferRef}>
            <GeoJSON key={Math.random()} data={toJS(publicInvestigation.publicInvestigationPerimeterBuffer)} className={classes.bufferPerimeter} />
          </FeatureGroup>
        </RenderIf>

        <RenderIf
          condition={
            (publicInvestigation.status > PUBLIC_INVESTIGATION_STATUS.START &&
              (publicInvestigation.type === PUBLIC_INVESTIGATION_TYPES.SELECT_SHAPES || publicInvestigation.type === PUBLIC_INVESTIGATION_TYPES.IMPORT_DATASET)) ||
            publicInvestigation.status >= PUBLIC_INVESTIGATION_STATUS.TYPE_INVESTIGATION
          }
        >
          <ParcelPolygonViewer {...polygonObject} />
        </RenderIf>

        <RenderIf condition={publicInvestigation.shape.features}>
          <FeatureGroup>
            <GeoJSON className={classes.drawedShape} key={JSON.stringify(publicInvestigation.shape)} data={publicInvestigation.shape} />
          </FeatureGroup>
        </RenderIf>

        <RenderIf
          condition={
            publicInvestigation.type === PUBLIC_INVESTIGATION_TYPES.DRAW &&
            publicInvestigation.status > PUBLIC_INVESTIGATION_STATUS.START &&
            publicInvestigation.status < PUBLIC_INVESTIGATION_STATUS.TYPE_SEARCH
          }
        >
          <EditControlExample
            onChange={async (geoJson) => {
              publicInvestigation.setDrawedGeoJson(geoJson);
            }}
            drawedGeoJson={publicInvestigation.drawedGeoJson}
          />
        </RenderIf>
        <RenderIf condition={publicInvestigation.type === PUBLIC_INVESTIGATION_TYPES.DRAW && publicInvestigation.status >= PUBLIC_INVESTIGATION_STATUS.TYPE_SEARCH}>
          <Pane style={{ zIndex: 500 }}>
            <GeoJSON
              ref={drawShapeRef}
              interactive={false}
              className={classes.drawedShape}
              key={JSON.stringify(publicInvestigation.drawedGeoJson)}
              data={publicInvestigation.drawedGeoJson}
            />
          </Pane>
        </RenderIf>

        <MapBaseLayer mapSelectorOpen={enableLayerSelector} onCloseSelector={setEnableLayerSelector} />
        <ControlButton onClick={(e) => setEnableLayerSelector(true)} position={"topright"} icon={<LayersIcon />} />
      </Map>
      <CustomLinearProgress style={{ opacity: loading ? 1 : 0 }} variant="query" color={"secondary"} />
      <ProgressStepper />
    </React.Fragment>
  );
});

export default withLeaflet(withStyles(styles)(MapPublicInvestigationView));
