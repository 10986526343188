import axios from "axios";
import jwt_decode from "jwt-decode";

const APP_ID = process.env.REACT_APP_APP_ID;
const AUTH_URL = window.env.AUTH_URL;
const API_URL = window.env.API_URL;

export const login = async (sessionId, fake) => {
  const response = await axios.get(API_URL + `acm/${fake ? `fakelogin/` : `login/${sessionId}`}`);
  const {
    data: { data: { token, role, nis, email, municipality, featuresForMunicipality, niscodes, boundary } },
  } = response;
  linkTokenToAxios(token);
  return {
    token,
    role,
    nis,
    email,
    municipality,
    featuresForMunicipality,
    niscodes,
    boundary,
  };
};

export const changeNis = async (newNis) => {
  let {
    data: { data: { token, role, nis, municipality, featuresForMunicipality, niscodes, boundary } },
  } = await axios.get(API_URL + `acm/changeNis/${newNis}`);
  linkTokenToAxios(token);
  return {
    token,
    role,
    nis,
    municipality,
    featuresForMunicipality,
    niscodes,
    boundary,
  };
};

export const validatetoken = async (sessionId) => {
  let {
    data: { data: { token, role, nis, municipality, featuresForMunicipality, niscodes, boundary } },
  } = await axios.get(API_URL + `acm/validatetoken/${sessionId}`);
  linkTokenToAxios(token);
  return {
    token,
    role,
    nis,
    municipality,
    featuresForMunicipality,
    niscodes,
    boundary,
  };
};

export const getUserRights = async (id) => {
  const { data } = await axios.get(`${AUTH_URL}choose?id=${id}`);
  return data;
};

export const checkMunicipalitySubscription = async (id) => {
  try {
    const test = await axios.get(`${API_URL}acm/checkSubscription/${id}`);
    return test;
  } catch (e) {
    console.log(e.toString());
    return false;
  }
};

export const logout = async (token) => {
  await axios.delete(API_URL + `acm/tokens/${token}`);
  linkTokenToAxios(null);
  return true;
};

export const tokenExpired = (token) => {
  let json = jwt_decode(token);
  let expTime = json.exp * 1000;
  let currentTime = Date.now();
  return currentTime >= expTime;
};

const linkTokenToAxios = (token) => {
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
};
