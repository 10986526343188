import axios from "axios";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { withStyles } from "@mui/styles";
import { getPublicInvestigationResearchTypes } from "config/config";
import { StoresContext } from "contexts";
import { observer as hooksObserver } from "mobx-react-lite";
import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
import { convertGeoJsonToBuffers, convertGeoJsonToBuffersByRadius } from "services/api";
import { PUBLIC_INVESTIGATION_RESEARCH_TYPE } from "stores/models/PublicInvestigationModel";
import { useDebouncedCallback } from "use-debounce";
import PerimeterSlider from "./PerimeterSlider";
import styles from "./SelectResearchTypeStyles";
import { Typography } from "@mui/material";
import SecondBufferControl from "./SecondBufferControl";

const SelectResearchType = hooksObserver(({ classes }) => {
  const {
    applicationStore: {
      publicInvestigation: {
        researchType,
        leggerType,
        setResearchType,
        perimeter,
        setPerimeter,
        location,
        locationIndicationOptions,
        setLocationIndicationOptions,
        setPublicInvestigationParcels,
        setPublicInvestigationBuffers,
        setPublicInvestigationPerimeterBuffer,
        secondBufferDistance,
        setSecondBufferDistance,
      },
      toggleLoading,
    },
  } = useContext(StoresContext);

  const [debouncedConvertGeoJsonToBuffers] = useDebouncedCallback(
    // function
    async (buffer) => {
      setPerimeter(buffer);
    },
    // delay in ms
    500,
  );

  const [debouncedSecondBuffer] = useDebouncedCallback(
    // function
    async (buffer) => {
      setSecondBufferDistance(buffer);
    },
    // delay in ms
    500,
  );

  useEffect(() => {
    let cancelSource;
    const getParcels = async () => {
      const cancelToken = axios.CancelToken;
      cancelSource = cancelToken.source();
      toggleLoading();
      try {
        const data = (await convertGeoJsonToBuffersByRadius(location, perimeter, leggerType, cancelSource)) || {};
        if (data) {
          const { parcels, buffer } = data;
          setPublicInvestigationParcels(parcels);
          setPublicInvestigationPerimeterBuffer(JSON.parse(buffer));
        }
      } catch (er) {
      } finally {
        cancelSource = null;
        toggleLoading();
      }
    };

    if (researchType === PUBLIC_INVESTIGATION_RESEARCH_TYPE.PERIMETER) {
      if (location.features && location.features.length > 0) getParcels();
    }

    return () => {
      if (cancelSource) {
        cancelSource.cancel();
      }
    };
  }, [perimeter, researchType, leggerType]);

  useEffect(() => {
    let cancelSource;

    const getParcels = async () => {
      const cancelToken = axios.CancelToken;
      cancelSource = cancelToken.source();
      switch (true) {
        case researchType === PUBLIC_INVESTIGATION_RESEARCH_TYPE.WITHIN_LOCATION:
        case researchType === PUBLIC_INVESTIGATION_RESEARCH_TYPE.LOCATION_INDICATION:
          setPublicInvestigationPerimeterBuffer(null);
          toggleLoading();
          try {
            const { capakeys, buffer100, buffer300 } = await convertGeoJsonToBuffers(location, leggerType, cancelSource, secondBufferDistance);
            setPublicInvestigationParcels(capakeys);
            setPublicInvestigationBuffers(buffer100, buffer300);
          } catch (er) {
          } finally {
            cancelSource = null;
            toggleLoading();
          }
          break;
        case researchType === PUBLIC_INVESTIGATION_RESEARCH_TYPE.PERIMETER:
          toggleLoading();
          try {
            // fix for showing buffers and shape when reload page on perimeter save
            const { buffer100, buffer300 } = await convertGeoJsonToBuffers(location, leggerType, cancelSource, secondBufferDistance);
            setPublicInvestigationBuffers(buffer100, buffer300);
            //
          } catch (er) {
          } finally {
            cancelSource = null;
            toggleLoading();
          }
          debouncedConvertGeoJsonToBuffers(perimeter);
          break;
        default:
      }
    };
    if (location.features && location.features.length > 0) getParcels();
    return () => {
      if (cancelSource) {
        cancelSource.cancel();
      }
    };
  }, [researchType, location, secondBufferDistance]);

  const handleChange = (name) => (event) => {
    setLocationIndicationOptions({
      ...locationIndicationOptions,
      [name]: event.target.checked,
    });
  };

  return (
    <div className={classes.container}>
      <RadioGroup aria-label="Research type" name="researchType" value={researchType}>
        <FormControlLabel
          value={PUBLIC_INVESTIGATION_RESEARCH_TYPE.LOCATION_INDICATION}
          control={<Radio />}
          label="Openbaar onderzoek met liggingsindicatie (voor Omgevingsvergunningen)"
          onChange={(event) => {
            setResearchType(event.target.value);
          }}
        />

        <FormControl component="fieldset" disabled={researchType !== PUBLIC_INVESTIGATION_RESEARCH_TYPE.LOCATION_INDICATION}>
          <FormGroup>
            {getPublicInvestigationResearchTypes(secondBufferDistance).map((subType) => (
              <>
                <FormControlLabel
                  key={subType.value}
                  control={<Checkbox value={subType.value} checked={locationIndicationOptions[subType.value]} onChange={handleChange(subType.value)} />}
                  label={subType.value === "B" ? <Typography variant="body2">Buiten een straal van 100m en</Typography> : subType.label}
                />
                {subType.value === "B" && (
                  <SecondBufferControl
                    defaultValue={secondBufferDistance}
                    onChange={debouncedSecondBuffer}
                    disabled={!locationIndicationOptions[subType.value] || researchType !== PUBLIC_INVESTIGATION_RESEARCH_TYPE.LOCATION_INDICATION}
                  />
                )}
              </>
            ))}
          </FormGroup>
        </FormControl>
        <FormControlLabel
          value={PUBLIC_INVESTIGATION_RESEARCH_TYPE.PERIMETER}
          control={<Radio />}
          label="Openbaar onderzoek met vrije perimeter"
          onChange={(event) => {
            setResearchType(event.target.value);
          }}
        />
        <PerimeterSlider
          defaultValue={perimeter}
          disabled={researchType !== PUBLIC_INVESTIGATION_RESEARCH_TYPE.PERIMETER}
          onChange={(value) => debouncedConvertGeoJsonToBuffers(value)}
        />

        <FormControlLabel
          value={PUBLIC_INVESTIGATION_RESEARCH_TYPE.WITHIN_LOCATION}
          control={<Radio />}
          label="Ruimtelijk onderzoek binnen de contour(en) van het gebied"
          onChange={(event) => {
            setLocationIndicationOptions({
              A: false,
              I: false,
              B: false,
              C: true,
            });
            setResearchType(event.target.value);
          }}
        />
      </RadioGroup>
    </div>
  );
});

SelectResearchType.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SelectResearchType);
