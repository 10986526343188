import { withStyles } from "@mui/styles";
import { StoresContext } from "contexts";
import { observer as hooksObserver } from "mobx-react-lite";
import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
import styles from "./ShowResultsStyles";
import { Typography, Button, FormControlLabel, Checkbox, FormControl, FormLabel, RadioGroup, Radio, Menu, MenuItem } from "@mui/material";
import { SubscriptionMessage } from "views/advanced-search/search-subscription-message";
import { SUBSCRIPTION_CODE } from "app-constants";
import { downloadPublicInvestigationShapeResults } from "services/api";

const ShowResults = hooksObserver(({ classes }) => {
  const {
    uiStore: {
      duplicateSpatialResearchResult,
      setDuplicateSpatialResearchResult,
      mapSpatialResearchResult,
      setMapSpatialResearchResult,
      parcelsInsideMunicipality,
      setParcelsInsideMunicipality,
    },
    applicationStore: { loading, publicInvestigationId, publicInvestigation: { titel, geoJsonData, lastResult, deduplicate } },
  } = useContext(StoresContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const hasGeoJsonData = !!geoJsonData && !!Object.keys(geoJsonData).length;
  useEffect(() => {
    setMapSpatialResearchResult(false);
    setParcelsInsideMunicipality(true);
  }, [geoJsonData]);

  const handleDownloadShapeFile = async (crs) => {
    await downloadPublicInvestigationShapeResults(publicInvestigationId, crs, titel);
  };

  useEffect(() => {
    if (deduplicate) {
      setDuplicateSpatialResearchResult(true);
    }
  }, [deduplicate]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (crs) => {
    if (crs) handleDownloadShapeFile(crs);
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <Typography variant="subtitle1" gutterBottom>
        Omschrijving: {titel}
      </Typography>
      <SubscriptionMessage code={SUBSCRIPTION_CODE.EXPORT_EXTERNAL_APP} />
      <FormControl component="fieldset" style={{ marginTop: 20 }} disabled={!hasGeoJsonData}>
        <FormLabel component="legend">Weergave</FormLabel>
        <RadioGroup
          aria-label="mapResult"
          name="mapResult"
          value={mapSpatialResearchResult ? "map" : "table"}
          onChange={(event) => setMapSpatialResearchResult(event.target.value === "map")}
        >
          <FormControlLabel value="table" control={<Radio />} label="Toon tabel" />
          <FormControlLabel style={{ marginTop: -5 }} value="map" control={<Radio />} label="Toon kaart" />
        </RadioGroup>
      </FormControl>
      <FormControl component="fieldset" style={{ marginTop: 20 }} disabled={!hasGeoJsonData}>
        <FormLabel component="legend">Gegevens</FormLabel>
        <RadioGroup
          aria-label="gegevens"
          name="gegevens"
          value={parcelsInsideMunicipality ? "in" : "out"}
          onChange={(event) => setParcelsInsideMunicipality(event.target.value === "in")}
        >
          <FormControlLabel value="in" control={<Radio />} label="Gegevens binnen gemeentegrens" />
          <FormControlLabel style={{ marginTop: -5 }} value="out" control={<Radio />} label="Gegevens buiten gemeentegrens" />
        </RadioGroup>
      </FormControl>
      <FormControl component="fieldset" style={{ marginTop: 20 }}>
        <FormLabel component="legend">Opties</FormLabel>
        <FormControlLabel
          control={<Checkbox checked={duplicateSpatialResearchResult} onChange={(event) => setDuplicateSpatialResearchResult(event.target.checked)} name="duplicateResult" />}
          label="Ontdubbelen i.f.v. aanschrijving"
          disabled={mapSpatialResearchResult}
        />
      </FormControl>
      <FormControl component="fieldset" style={{ marginTop: 20 }}>
        <FormLabel component="legend">Download</FormLabel>
        <Button style={{ marginTop: 10 }} disabled={!lastResult?.length || !hasGeoJsonData || loading} onClick={handleClick} variant="outlined" size="small" fullWidth>
          Shape
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => handleClose()}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={() => handleClose(4326)}>WGS84 (EPSG:4326)</MenuItem>
          <MenuItem onClick={() => handleClose(31370)}>Lambert 72 (EPSG:31370)</MenuItem>
          <MenuItem onClick={() => handleClose(3812)}>Lambert 2008 (EPSG:3812)</MenuItem>
        </Menu>
      </FormControl>
    </div>
  );
});

ShowResults.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ShowResults);
