import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Input from "@mui/material/Input";
import Typography from "@mui/material/Typography";
import React, { useEffect } from "react";
import styles from "./PerimeterSliderStyles";
import debounce from "debounce";

const MIN_VALUE = 101;
const MAX_VALUE = 1000;

const SecondBufferControl = ({ classes, onChange, defaultValue, disabled }) => {
  const [value, setValue] = React.useState(defaultValue);

  const debouncedOnChange = debounce(onChange);

  const handleInputChange = (event) => {
    setValue(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < MIN_VALUE) {
      setValue(MIN_VALUE);
    } else if (value > MAX_VALUE) {
      setValue(MAX_VALUE);
    }
  };

  useEffect(() => {
    if (value >= MIN_VALUE && value <= MAX_VALUE) {
      debouncedOnChange(value, 1000);
    }
  }, [value]);

  return (
    <div style={{ marginTop: -13, marginLeft: 32, display: "flex", flexDirection: "row", alignItems: "center" }}>
      <Typography variant="body2" sx={{ marginRight: 1 }}>
        binnen
      </Typography>
      <Input
        disabled={disabled}
        value={value}
        onChange={handleInputChange}
        onBlur={handleBlur}
        sx={{ fontSize: "0.875rem", textAlign: "center" }}
        inputProps={{
          step: 10,
          min: MIN_VALUE,
          max: MAX_VALUE,
          type: "number",
          "aria-labelledby": "input-slider",
          style: { textAlign: "center" },
        }}
      />
      <Typography variant="body2" sx={{ marginLeft: 1 }}>
        m. (B)
      </Typography>
    </div>
  );
};

export default withStyles(styles)(SecondBufferControl);
