import { action, observable, computed, autorun } from "mobx";
import axios from "axios";

import { getParcels, searchPerson, searchPersonByInsz, searchCompanyByName, searchCompanyByNumber, getMagdaParcelExists } from "services/api";

const CancelToken = axios.CancelToken;
export default class searchModel {
  @observable
  persons = [];

  @observable
  parcels = [];

  @observable
  personParcels = [];

  @observable
  activeOfficialId;

  @observable
  loading = false;

  @observable
  source;

  @action
  searchPerson = async (type, firstname = "", lastname = "", year = "") => {
    this.loading = true;
    this.persons = [];
    const { data } = await searchPerson(type, firstname, lastname, year);
    this.persons = data;
    this.loading = false;
  };

  @action
  searchPersonByInsz = async (type, insz) => {
    this.loading = true;
    this.persons = [];
    const { data } = await searchPersonByInsz(type, insz);
    this.persons = data;
    this.loading = false;
  };

  @action
  searchCompanyByName = async (type, name) => {
    this.loading = true;
    this.persons = [];
    const { data } = await searchCompanyByName(type, name);
    this.persons = data;
    this.loading = false;
  };

  @action
  searchCompanyByNumber = async (type, insz) => {
    this.loading = true;
    this.persons = [];
    const { data } = await searchCompanyByNumber(type, insz);
    this.persons = data;
    this.loading = false;
  };

  @action
  searchMagdaParcelExists = async (capakey) => {
    this.loading = true;
    this.parcels = [];
    const { data } = await getMagdaParcelExists(capakey);
    if (data === true) {
      this.parcels.push({ capakey });
    }
    this.loading = false;
    return data;
  };

  @action
  getParcels = async (type, officialId, afdeling = null) => {
    if (this.loading && this.source) {
      this.source.cancel("Operation canceled by the user.");
    }
    this.source = CancelToken.source();
    this.loading = true;
    this.activeOfficialId = officialId;
    this.personParcels = [];

    try {
      const { data } = await getParcels(type, officialId, afdeling, this.source);
      this.personParcels = data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.toString());
      }
    }
    this.loading = false;
  };

  @action
  setLoading = (value) => {
    this.loading = value;
  };

  @action
  searchPersonByInsz = async (leggerType, officialId) => {
    const { data } = await searchPersonByInsz(leggerType, officialId);
    this.activeOfficialId = officialId;
    this.persons = data;
  };

  @action
  searchCompanyByInsz = async (leggerType, officialId) => {
    const { data } = await searchCompanyByNumber(leggerType, officialId);
    this.activeOfficialId = officialId;
    this.persons = data;
  };

  @computed
  get activePerson() {
    console.log(this.persons);
    console.log(this.activeOfficialId);
    if (this.activeOfficialId) {
      const person = this.persons.find((person) => {
        return person.officialId === this.activeOfficialId;
      });
      return person;
    }
  }
}
