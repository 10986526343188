import { MapLayer, withLeaflet } from "react-leaflet";
import * as PropTypes from "prop-types";
import vectorTileLayer from "leaflet-vector-tile-layer";

class VectorTileLayer extends MapLayer {
  static propTypes = {
    leaflet: PropTypes.shape({
      map: PropTypes.object.isRequired,
      pane: PropTypes.object,
      layerContainer: PropTypes.object.isRequired,
    }),
    url: PropTypes.string.isRequired,
  };

  createLeafletElement(props) {
    const layer = vectorTileLayer(props.url, { ...props });
    return layer;
  }
}

export default withLeaflet(VectorTileLayer);
